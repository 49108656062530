<template>
  <PageTitleTop>
    <template v-slot:title>账户绑定</template>
    <PageWarnMessage>
      为了保障您的资金账户安全，您需完成企业账户认证
    </PageWarnMessage>
    <Form
      ref="formValidate"
      :model="formValidate"
      :rules="ruleValidate"
      :label-width="130"
      label-position="left"
    >
      <PageSection>
        <template v-slot:title>开户行查询</template>
        <Row class="form-row">
          <Col span="12">
            <FormItem label="开户行" prop="name">
              <Input
                v-model="formValidate.name"
                placeholder="请输入开户行名称:如 招商银行"
                class="ivu-input-fix"
              ></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="省份/城市" prop="provinceAndCity">
              <Cascader
                v-model="formValidate.provinceAndCity"
                v-width="200"
                :data="provinceAndCityOptions"
                @on-change="cityChange"
              />
            </FormItem>
          </Col>
        </Row>
        <Row class="form-row">
          <Col span="12">
            <FormItem label="&nbsp;&nbsp;&nbsp;支行" prop="subBankName">
              <Input
                v-model="formValidate.subBankName"
                placeholder="请输入支行名称"
                class="ivu-input-fix"
              ></Input>
            </FormItem>
          </Col>
        </Row>
      </PageSection>
      <Button
        style="margin-bottom: 40px"
        class="commit-btn"
        @click="commitClick"
      >
        查询开户行
      </Button>
    </Form>

    <Form
      v-if="isSearchBank"
      ref="formValidate"
      :model="formValidateBind"
      :rules="ruleValidateBind"
      :label-width="130"
      label-position="left"
    >
      <PageSection>
        <template v-slot:title>账户绑定</template>
        <Row class="form-row">
          <Col span="12">
            <FormItem label="开户行行号" prop="bindBankNo">
              <Select v-model="model" style="width: 296px">
                <Option v-for="item in bankLink" :key="item" :value="item">
                  {{ item.bankName }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="12"></Col>
        </Row>
        <Row class="form-row">
          <Col span="12">
            <FormItem label="开户行账号" prop="bindAcctNo">
              <Input
                v-model="formValidateBind.bindAcctNo"
                placeholder="请输入开户行账号"
                class="ivu-input-fix"
              ></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="银行账户名称" prop="bindEntpsName">
              <Input
                v-model="formValidateBind.bindEntpsName"
                placeholder="请输入银行账户名称"
                class="ivu-input-fix"
              ></Input>
            </FormItem>
          </Col>
        </Row>
        <Row class="bind-success-msg">
          {{ bindSuccessMsg }}
        </Row>
      </PageSection>
      <div class="agree">
        <Button class="commit-btn" @click="bindCard">账户绑定</Button>
        <Checkbox v-model="single"  style="margin-left:15px">
          <span>我已同意</span>
          <a
            href="https://file.qiludazong.cn/file/3973145394870520.pdf"
            target="_blank"
          >
            《会员入市协议》
          </a>
          <span>和</span>
          <a
            href="https://file.qiludazong.cn/file/6714039311566331.pdf"
            target="_blank"
          >
            《会员交易账户服务协议》
          </a>
        </Checkbox>
      </div>
    </Form>
  </PageTitleTop>
</template>

<script>
import Message from '@/components/library/Message'
export default {
  name: 'AccountBalance',
  data () {
    return {
			single: false,
      isOpenAcount: true,
      isSearchBank: false,
      provinceAndCityOptions: [],
      bankLink: [],
      model: '',

      formValidate: {
        name: null,
        provinceAndCity: null,
        subBankName: null,
      },
      ruleValidate: {
        name: [{ required: true, message: '开户行不能为空', trigger: 'blur' }],
        provinceAndCity: [
          {
            required: true,
            message: '省份/城市不能为空',
            trigger: 'change',
            type: 'array',
          },
        ],
      },
      formValidateBind: {
        bindBankNo: null,
        bindBankName: null,
        bindAcctNo: null,
        bindEntpsName: null,
      },
      ruleValidateBind: {
        bindBankNo: [
          { required: true, message: '开户行行号不能为空', trigger: 'blur' },
        ],
        bindBankName: [
          { required: true, message: '开户行名称不能为空', trigger: 'blur' },
        ],
        bindAcctNo: [
          { required: true, message: '开户行账号不能为空', trigger: 'blur' },
        ],
        bindEntpsName: [
          {
            required: true,
            message: '银行卡账户名称不能为空',
            trigger: 'blur',
          },
        ],
      },
      getBankParams: {
        name: null,
        province: null,
        city: null,
        subBankName: null,
      },
      bindSuccessMsg: '',
    }
  },
  created () {
    this.getProvinceAndCityOptions()
  },
  computed: {
    bindBankName () {
      return this.formValidate.name
    },
  },
  methods: {
    getProvinceAndCityOptions () {
      this.$store
        .dispatch('accountMoney/GetProvinceAndCityOptions')
        .then((res) => {
          if (res.returnCode === '1') {
            const result = []
            this.provinceAndCityOptions = this.getCityOptions(
              res.result,
              result,
            )
          }
        })
        .catch(() => {})
    },
    getCityOptions (data, result) {
      for (const item of data) {
        const obj = {
          value: item.name,
          label: item.name,
        }
        result.push(obj)
        if (item.list) {
          obj.children = []
          this.getCityOptions(item.list, obj.children)
        }
      }
      return result
    },
    cityChange (val) {
      this.getBankParams.province = val[0]
      this.getBankParams.city = val[1]
    },
    commitClick () {
      this.$refs.formValidate.validate((valid) => {
        if (!valid) {
          this.$Message.error('请填写必填项!')
          return
        }
        this.$Spin.show({
          render: (h) => {
            return h('div', [
              h(
                'div',
                {
                  style: {
                    color: '#d12b23',
                    fontSize: '22px',
                  },
                },

                '正在加载银行数据。请等待...',
              ),
            ])
          },
        })
        this.getBankParams.name = this.formValidate.name
        this.getBankParams.subBankName = this.formValidate.subBankName
        this.$store
          .dispatch('accountMoney/GetOpenAccoutBank', this.getBankParams)
          .then((res) => {
            this.$Spin.hide()
            if (res.returnCode === '1') {
              // this.formValidateBind.bindBankNo = res.result.bankNo
              // this.formValidateBind.bindBankName = res.result.bankName
              this.bankLink = res.result
              this.isSearchBank = true
            }
          })
          .catch(() => {})
      })
    },
    bindCard () {
      if (!this.single) {
        Message({ type: 'error', text: '请勾选同意' })
        return
      }
      const postData = {
        bindBankNo: this.model.bankNo,
        bindBankName: this.model.bankName,
        bindAcctNo: this.formValidateBind.bindAcctNo,
        bindEntpsName: this.formValidateBind.bindAcctNo,
      }
      this.$Spin.show({
        render: (h) => {
          return h('div', [
            h(
              'div',
              {
                style: {
                  color: '#d12b23',
                  fontSize: '22px',
                },
              },

              '正在绑定。请等待...',
            ),
          ])
        },
      })

      this.$store
        .dispatch('accountMoney/BindBankCard', postData)
        .then((res) => {
          this.$Spin.hide()
          if (res.returnCode === '1') {
            this.$router.push('/user/account-bind/info')
            this.$Message.success(`绑定成功，${res.result.statusStr}`)
          }
        })
        .catch(() => {})
    },
  },
  components: {},
  watch: {
    // model (val) {
    //   this.formValidateBind.bindAcctNo = val.bankNo
    //   console.log('val', val)
    // },
    bindBankName (val) {
      // console.log('bindBankName', val)
      this.isSearchBank = false
    },
  },
}
</script>

<style lang="less" scoped>
.ivu-input-fix {
  width: 80%;
}
/deep/ .section-main {
  margin-bottom: 20px;
}
.bind-success-msg {
  color: @xtxColor;
}
.agree {
  a {
    color: #069;
  }
}
/deep/ .ivu-checkbox-checked .ivu-checkbox-inner {
  border-color: #d12b23;
  background-color: #d12b23;
}
</style>
